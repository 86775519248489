import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
	modulo?: number;
	seccion?: number;
}

@Injectable({
    providedIn: "root",
})
export class NavService implements OnDestroy {
    private unsubscriber: Subject<any> = new Subject();
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    // Search Box
    public search: boolean = false;

    // Language
    public language: boolean = false;

    // Mega Menu
    public megaMenu: boolean = false;
    public levelMenu: boolean = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth < 991 ? false : true;

    // Full screen
    public fullScreen: boolean = false;

    constructor(private router: Router) {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, "resize")
        .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
        .subscribe((evt: any) => {
            this.setScreenWidth(evt.target.innerWidth);
            if (evt.target.innerWidth < 991) {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            }
            if (evt.target.innerWidth < 1199) {
                this.megaMenuColapse = true;
            }
        });
        if (window.innerWidth < 991) {
            // Detect Route change sidebar close
            this.router.events.subscribe((event) => {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            });
        }
    }

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }

    MENUITEMS: Menu[] = [
        {
            path: "/app/inicio",
            title: "Inicio",
            icon: "fa-dashboard",
            type: "link",
            bookmark: true,
            active: true,
        },
        {
            title: "Linea de Negocios",
            icon: "fa-building",
            type: "sub",
            active: false,
            modulo: 1,
            children: [
                /* { 
					path: '/app/negocio/reservas',
					title: 'Reservas',
					type: 'link',
					seccion: 26
				}, */
                { 
					path: '/app/negocio/arriendos',
					title: 'Arriendos',
					type: 'link',
					seccion: 27
				},
                { 
					path: '/app/negocio/ingresos',
					title: 'Ingresos',
					type: 'link',
					seccion: 28
				},
                { 
					path: '/app/negocio/egresos',
					title: 'Egresos',
					type: 'link',
					seccion: 29
				}
            ]
        },
        {
            title: "Informes",
            icon: "fa-bar-chart-o",
            type: "sub",
            active: false,
            modulo: 2,
            children: [
                { 
					path: '/app/informes/egresos',
					title: 'Egresos',
					type: 'link',
					seccion: 32
				},
                { 
					path: '/app/informes/arriendos-morosos',
					title: 'Arriendos Morosos',
					type: 'link',
					seccion: 33
				}
            ]
        },
        /* {
            title: "Gestor de Documentos",
            icon: "fa-list-alt",
            type: "sub",
            active: false,
            modulo: 3,
            children: [
                { 
					path: '/app/negocio/egresos',
					title: 'Gestión de Documentos',
					type: 'link',
					seccion: 29
				},
                { 
					path: '/app/negocio/egresos',
					title: 'Tipos de Documentos',
					type: 'link',
					seccion: 29
				},
                { 
					path: '/app/negocio/egresos',
					title: 'Cláusulas',
					type: 'link',
					seccion: 29
				}
            ]
        }, */
        {
            title: "Utilidades",
            icon: "fa-wrench",
            type: "sub",
            active: false,
            modulo: 4,
            children: [
                { 
					path: '/app/utilidades/periodos',
					title: 'Periodos Contables',
					type: 'link',
					seccion: 30
				},
                { 
					path: '/app/utilidades/periodos',
					title: 'Canales de Difusión',
					type: 'link',
					seccion: 30
				},
                { 
					path: '/app/utilidades/periodos',
					title: 'Campañas',
					type: 'link',
					seccion: 30
				}
            ]
        },
        {
            title: "Maestros",
            icon: "fa-th-list",
            type: "sub",
            active: false,
            modulo: 5,
            children: [
                { 
					path: '/app/maestros/trabajadores',
					title: 'Trabajadores',
					type: 'link',
					seccion: 17
				},
                { 
					path: '/app/maestros/clientes',
					title: 'Clientes',
					type: 'link',
					seccion: 18
				},
                { 
					path: '/app/maestros/proveedores',
					title: 'Proveedores',
					type: 'link',
					seccion: 19
				},
                { 
					path: '/app/maestros/sectores',
					title: 'Sectores',
					type: 'link',
					seccion: 20
				},
                { 
					path: '/app/maestros/condominios',
					title: 'Condominios',
					type: 'link',
					seccion: 21
				},
                { 
					path: '/app/maestros/edificios',
					title: 'Edificios',
					type: 'link',
					seccion: 22
				},
                { 
					path: '/app/maestros/proyectos',
					title: 'Proyectos',
					type: 'link',
					seccion: 34
				},
                {
					title: 'Propiedades',
					type: 'sub',
					active: false,
                    children: [
                        { 
                            path: '/app/maestros/propiedades',
                            title: 'Propiedades',
                            type: 'link',
                            seccion: 23
                        },
                        { 
                            path: '/app/maestros/propiedades-tipos',
                            title: 'Tipos de Prop.',
                            type: 'link',
                            seccion: 24
                        },
                        { 
                            path: '/app/maestros/propiedades-subtipos',
                            title: 'SubTipos de Prop.',
                            type: 'link',
                            seccion: 25
                        }
                    ]
				}
            ]
        },
        {
            title: "Mantenedores",
            icon: "fa-align-justify",
            type: "sub",
            active: false,
            modulo: 6,
            children: [
                { 
					path: '/app/mantenedores/departamentos',
					title: 'Departamentos',
					type: 'link',
					seccion: 3
				},
				{ 
					path: '/app/mantenedores/cargos',
					title: 'Cagos',
					type: 'link',
					seccion: 4
				},
                { 
					path: '/app/mantenedores/documentos',
					title: 'Documentos',
					type: 'link',
					seccion: 5
				},
                { 
					path: '/app/mantenedores/afps',
					title: 'AFP',
					type: 'link',
					seccion: 6
				},
                { 
					path: '/app/mantenedores/bancos',
					title: 'Bancos',
					type: 'link',
					seccion: 7
				},
                { 
					path: '/app/mantenedores/centros-costos',
					title: 'Centros de Costos',
					type: 'link',
					seccion: 8
				},
                { 
					path: '/app/mantenedores/monedas',
					title: 'Monedas',
					type: 'link',
					seccion: 9
				},
                { 
					path: '/app/mantenedores/formas-pagos',
					title: 'Formas de Pagos',
					type: 'link',
					seccion: 10
				},
                { 
					path: '/app/mantenedores/paises',
					title: 'Paises',
					type: 'link',
					seccion: 11
				},
                { 
					path: '/app/mantenedores/regiones',
					title: 'Regiones',
					type: 'link',
					seccion: 12
				},
                { 
					path: '/app/mantenedores/comunas',
					title: 'Comunas',
					type: 'link',
					seccion: 13
				},
                { 
					path: '/app/mantenedores/conservadores',
					title: 'Conservadores',
					type: 'link',
					seccion: 14
				},
                { 
					path: '/app/mantenedores/profesiones',
					title: 'Profesiones',
					type: 'link',
					seccion: 15
				},
                { 
					path: '/app/mantenedores/cuentas-tipos',
					title: 'Tipos de Cuentas',
					type: 'link',
					seccion: 16
				}
            ]
        },
        {
            title: "Centro de Usuarios",
            icon: "fa-users",
            type: "sub",
            active: false,
            modulo: 7,
            children: [
                { 
					path: '/app/centrousuarios/usuarios',
					title: 'Usuarios',
					type: 'link',
					seccion: 1
				},
				{ 
					path: '/app/centrousuarios/bitacora',
					title: 'Bitácora',
					type: 'link',
					seccion: 2
				}
            ]
        },
        {
            title: "Configuraciones",
            icon: "fa-cog",
            type: "sub",
            active: false,
            modulo: 8,
            children: [
                { 
					path: '/app/centrousuarios/bitacora',
					title: 'Empresa',
					type: 'link',
					seccion: 2
				},
                { 
					path: '/app/centrousuarios/bitacora',
					title: 'Parámetros',
					type: 'link',
					seccion: 2
				}
            ]
        }
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
